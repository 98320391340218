/** @jsx jsx */
import React from 'react'
import Router from 'next/router'
import { jsx, Container, Text } from 'theme-ui'
import { useAuthenticatedUser } from '../context/authContext'
import Layout from '../layouts/main'
import { Header } from '../components/Header'
import { ReserveUsername } from '../components/Actions'
import { NextSeo } from 'next-seo'
import { getOpenGraphImageUrl, OPENGRAPH_IMAGE_WIDTH, OPENGRAPH_IMAGE_HEIGHT } from '@/helpers/SocialCard'

const IndexPage = () => {
  const { authenticatedUser, authIsLoading } = useAuthenticatedUser()

  if (authIsLoading) {
    return null
  }

  if (authenticatedUser) {
    Router.replace('/home')
    return null
  }

  let title = 'Sharemark'
  let description = 'Sharemark is a forthcoming publishing platform. Reserve your username today.'
  let url = 'https://sharemark.co'

  let image = {
    url: getOpenGraphImageUrl(null, ' ', title, description),
    width: OPENGRAPH_IMAGE_WIDTH,
    height: OPENGRAPH_IMAGE_HEIGHT
  }

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url: url,
          title: title,
          description: description,
          site_name: 'Sharemark',
          images: [image]
        }}
      />
      <Text mb={3}>
        <strong>Sharemark</strong> is a forthcoming publishing platform.
        Reserve your username and be notified when we open for beta testing.
      </Text>
      <ReserveUsername />
    </>
  )
}


IndexPage.getLayout = page => {
  return (
    <Layout header={<Header />}>
      <Container>
        {page}
      </Container>
    </Layout>
  )
}

export default IndexPage