export const OPENGRAPH_IMAGE_WIDTH = 1200
export const OPENGRAPH_IMAGE_HEIGHT = 628

export const getOpenGraphImageUrl = (image, slug, title, body) => {
  if (image) {
    return image
  }

  if (!slug || !title || !body) {
    return ''
  }

  const encodedSlug = encodeURIComponent(slug.substring(0, 100))
  const encodedTitle = encodeURIComponent(title.substring(0, 100))
  const encodedBody = encodeURIComponent(body.substring(0, 600))

  const url = `https://feedcard.cloud/api/render?siteId=2&slug=${encodedSlug}&title=${encodedTitle}&quote=${encodedBody}`

  return url
}